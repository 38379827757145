<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="attendantUpdating" class="modal-card-title">Atualizar informações do atendente</p>
            <p v-else class="modal-card-title">Inserir atendente</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important;">
            <b-field label="Id" v-if="attendantUpdating">
              <b-input
                type="text"
                v-model="id"
                placeholder="Id"
                v-if="attendantUpdating"
                disabled
              >
              </b-input>
            </b-field>
            <b-field label="Nome" :message="invalidName.message" :type="invalidName.style">
              <b-input
                type="text"
                v-model="name"
                placeholder="Nome"
                @input="invalidName.message = ''; invalidName.style = ''"
                required>
              </b-input>
            </b-field>
            <b-field label="Username" :message="invalidUsername.message" :type="invalidUsername.style">
              <b-input
                type="text"
                v-model="username"
                placeholder="Username"
                @input="verifyUsername"
                required>
              </b-input>
            </b-field>
            <b-field class="label-password" :message="invalidPassword.message" :type="invalidPassword.style" :label="!attendantUpdating ? 'Senha' : 'Senha (deixe em branco para não alterar)'">
              <b-input
                type="password"
                v-model="password"
                placeholder="Senha"
                @input="verifyPassword"
                :required="!attendantUpdating">
              </b-input>
            </b-field>
            <b-field label="Departamento" :message="invalidDepartment.message" :type="invalidDepartment.style">
              <multiselect
                v-model="selectedDepartments"
                :multiple="true"
                :options="departments"
                label="name"
                track-by="id"
                select-label=""
                deselect-label=""
                @remove="updateDepartmentsSupervisor"
              >
                <template slot="placeholder"
                  >Departamentos</template
                >
              </multiselect>
            </b-field>
            <!-- <div class="notification is-danger is-light" v-if="attendantUpdating && (departmentsSelected !== attendantUpdating.department_id)">
              Ao alterar o departamento, os encarteiramentos do atendente serão removidos
            </div> -->
            <b-field label="Departamentos supervisionados" :message="invalidSupervisor.message" :type="invalidSupervisor.style">
              <multiselect
                v-model="departmentsSupervisor"
                :multiple="true"
                :options="selectedDepartments"
                label="name"
                track-by="id"
                select-label=""
                deselect-label=""
              >
                <template slot="placeholder"
                  >Departamentos supervisionados</template
                >
              </multiselect>
            </b-field>
            <div class="field">
              <b-checkbox v-model="supervisor_all_departments">Supervisor de TODOS os departamentos</b-checkbox>
            </div>
            <div class="field">
              <b-checkbox v-model="isActive">Usuário ativo</b-checkbox>
            </div>
            <b-field label="Permissões">
              <div class="field">
                <b-checkbox :disabled="supervisor_all_departments" v-model="import_csv">Importar contatos por CSV</b-checkbox>
              </div>
              <div class="field">
                <b-checkbox :disabled="supervisor_all_departments" v-model="schedule_messages">Acessar tela de mensagens agendadas</b-checkbox>
              </div>
            </b-field>
            <b-field label="Código customizado">
              <b-input
                type="text"
                v-model="custom_code"
                placeholder="Código customizado">
              </b-input>
            </b-field>

            <b-field label="Perfil de permissões" expanded>
              <b-select
                v-model="permission_id"
                :disabled="supervisor_all_departments"
                placeholder="Selecione um perfil de permissões" expanded>
                <option :key="null" :value="null"></option>
                <option
                  v-for="permission in permissions"
                  :key="permission.id"
                  :value="permission.id"
                >
                  {{ permission.permission_name }}
                </option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
            <button v-if="!!attendantUpdating" class="button is-primary" @click="updateAttendant">Atualizar</button>
            <button v-else class="button is-primary" @click="addAttendant">Gravar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'ModalInsertAttendant',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    attendantUpdating: {
      type: Object,
      required: false
    },
    usernames: {
      type: Array,
      required: true
    },
    permissions: {
      type: Array,
      required: true
    }
  },
  components: {
    multiselect
  },
  data () {
    return {
      name: '',
      password: '',
      username: '',
      selectedDepartments: [],
      departmentsSupervisor: [],
      supervisor: false,
      supervisor_all_departments: false,
      isActive: true,
      custom_code: '',
      import_csv: false,
      schedule_messages: false,
      permission_id: null,
      invalidName: {
        message: '',
        style: ''
      },
      invalidUsername: {
        message: '',
        style: ''
      },
      invalidPassword: {
        message: '',
        style: ''
      },
      invalidDepartment: {
        message: '',
        style: ''
      },
      invalidSupervisor: {
        message: '',
        style: ''
      },
      id: ''
    }
  },
  watch: {
    supervisor_all_departments (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.selectedDepartments = JSON.parse(JSON.stringify(this.departments))
        this.departmentsSupervisor = JSON.parse(JSON.stringify(this.departments))
        this.import_csv = true
        this.schedule_messages = true
      } else if (!newValue && oldValue && this.departmentsSupervisor.length === this.departments.length) {
        this.selectedDepartments = []
        this.departmentsSupervisor = []
        this.import_csv = false
        this.schedule_messages = false
      }
    },
    departmentsSupervisor () {
      if (this.supervisor_all_departments && this.departments.length > this.departmentsSupervisor.length) {
        this.supervisor_all_departments = false
      } else if (!this.supervisor_all_departments && this.departments.length === this.departmentsSupervisor.length) {
        this.supervisor_all_departments = true
      }
    }
  },
  created () {
    if (this.attendantUpdating) {
      this.name = this.attendantUpdating.name
      this.password = this.attendantUpdating.password
      this.username = this.attendantUpdating.username
      this.selectedDepartments = this.attendantUpdating.departments
      this.departmentsSupervisor = this.attendantUpdating.departments.filter(dpt => dpt.supervisor)
      this.supervisor = this.attendantUpdating.supervisor
      this.supervisor_all_departments = this.attendantUpdating.supervisor_all_departments
      this.isActive = this.attendantUpdating.isActive
      this.custom_code = this.attendantUpdating.custom_code
      this.import_csv = this.attendantUpdating.import_csv
      this.schedule_messages = this.attendantUpdating.schedule_messages
      this.id = this.attendantUpdating.id
      this.permission_id = this.attendantUpdating.permission_id
    }
  },
  methods: {
    updateDepartmentsSupervisor (dpt) {
      this.departmentsSupervisor = this.departmentsSupervisor.filter(el => el.id !== dpt.id)
    },
    updateAttendant () {
      this.supervisor = this.departmentsSupervisor.length > 0 || this.supervisor_all_departments
      if (!this.validateForm()) return

      let message = ''
      let title = ''
      let confirmText = ''

      if (this.password && this.attendantUpdating && this.username !== this.attendantUpdating.username) {
        message = `Deseja realmente <b>alterar a senha e o username</b> para o atendente <b>${this.name}</b>?`
        title = 'Atualização de username e senha do atendente'
        confirmText = 'Alterar senha e username'
      } else if (this.password && this.attendantUpdating) {
        message = `Deseja realmente <b>alterar a senha</b> para o atendente <b>${this.name}</b>?`
        title = 'Atualização de senha do atendente'
        confirmText = 'Alterar senha'
      } else if (this.attendantUpdating && this.username !== this.attendantUpdating.username) {
        message = `Deseja realmente <b>alterar o username</b> para o atendente <b>${this.name}</b>?`
        title = 'Atualização de username do atendente'
        confirmText = 'Alterar username'
      }

      if (message || message !== '') {
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          confirmText: confirmText,
          type: 'is-danger',
          hasIcon: true,
          cancelText: 'Cancelar',
          onConfirm: () => this.emitUpdateAttendant({
            id: this.attendantUpdating.id,
            name: this.name,
            password: this.password,
            username: this.username,
            departments: this.adaptDepartments(),
            supervisor: this.supervisor,
            supervisor_all_departments: this.supervisor_all_departments,
            isActive: this.isActive,
            custom_code: this.custom_code,
            import_csv: this.import_csv,
            schedule_messages: this.schedule_messages,
            permission_id: this.permission_id
          })
        })
      } else {
        this.emitUpdateAttendant({
          id: this.attendantUpdating.id,
          name: this.name,
          password: this.password,
          username: this.username,
          departments: this.adaptDepartments(),
          supervisor: this.supervisor,
          supervisor_all_departments: this.supervisor_all_departments,
          isActive: this.isActive,
          custom_code: this.custom_code,
          import_csv: this.import_csv,
          schedule_messages: this.schedule_messages,
          permission_id: this.permission_id
        })
      }
    },
    emitUpdateAttendant (attendant) {
      this.$emit('updateAttendant', attendant)
    },
    addAttendant () {
      this.supervisor = this.departmentsSupervisor.length > 0 || this.supervisor_all_departments
      if (!this.validateForm()) return

      this.$emit('addAttendant', {
        name: this.name,
        password: this.password,
        username: this.username,
        departments: this.adaptDepartments(),
        supervisor: this.supervisor,
        supervisor_all_departments: this.supervisor_all_departments,
        isActive: this.isActive,
        import_csv: this.import_csv,
        schedule_messages: this.schedule_messages
      })
    },
    validateForm () {
      let isValid = true

      if (this.name.length === 0) {
        this.invalidName.message = 'Informe um nome!'
        this.invalidName.style = 'is-danger'
        isValid = false
      } else {
        this.invalidName.message = ''
        this.invalidName.style = ''
      }

      if (this.username.length === 0) {
        this.invalidUsername.message = 'Informe um username!'
        this.invalidUsername.style = 'is-danger'
        isValid = false
      } else {
        this.invalidUsername.message = ''
        this.invalidUsername.style = ''
      }

      if (!this.attendantUpdating && this.password.length === 0) {
        this.invalidPassword.message = 'Informe uma senha!'
        this.invalidPassword.style = 'is-danger'
        isValid = false
      } else {
        this.invalidPassword.message = ''
        this.invalidPassword.style = ''
      }

      if (this.selectedDepartments.length === 0) {
        this.invalidDepartment.message = 'Selecione pelo menos um departamento!'
        this.invalidDepartment.style = 'is-danger'
        isValid = false
      } else {
        this.invalidDepartment.message = ''
        this.invalidDepartment.style = ''
      }

      if (!this.verifyPassword()) {
        isValid = false
      }

      return isValid
    },
    verifyUsername () {
      const username = this.usernames.filter(u => u === this.username)
      if (this.username.toLowerCase() !== 'admin' && ((this.attendantUpdating && this.username === this.attendantUpdating.username) || username.length === 0)) {
        this.invalidUsername.message = ''
        this.invalidUsername.style = ''
      } else {
        this.invalidUsername.message = 'username indisponível'
        this.invalidUsername.style = 'is-danger'
      }
    },
    verifyPassword () {
      if ((this.attendantUpdating && this.password) || (!this.attendantUpdating)) {
        if (this.password.length < 6 && this.password?.length >= 0) {
          this.invalidPassword.message = 'A senha deve ter no mínimo 6 caracteres!'
          this.invalidPassword.style = 'is-danger'
          return false
        } else {
          this.invalidPassword.message = ''
          this.invalidPassword.style = ''
          return true
        }
      }
      return true
    },
    closeModal () {
      this.$emit('closeModal')
    },
    adaptDepartments () {
      return this.selectedDepartments.map(el => {
        if (this.departmentsSupervisor.find(dpt => dpt.id === el.id)) {
          el.supervisor = true
        } else {
          el.supervisor = false
        }

        return el
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }

  .modal-card-body {
    overflow: visible !important;
  }

  // /deep/ .label-password > .label {
  //   color: red
  // }
</style>
