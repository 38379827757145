<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Atendentes
    </hero-bar>
    <section class="section is-main-section">
      <ModalInsertAttendant
        v-if="isComponentModalActive"
        v-on:addAttendant="handleAddAttendant"
        v-on:updateAttendant="handleUpdateAttendant"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive"
        :departments="allDepartments"
        :attendantUpdating="attendantUpdating"
        :usernames="usernames"
        :permissions="allPermissions"
      />
      <b-loading
        v-if="fetchingAttendants"
        :is-full-page="true"
        v-model="fetchingAttendants"
        :can-cancel="false"
      ></b-loading>
      <AttendantsList
        v-else
        :attendants="allAttendants"
        v-on:removeAttendant="removeAttendant"
        v-on:updateAttendant="OpenUpdateAttendant"
      />
      <FabButton
        v-on:addClick="handleAddClick"
        iconClass="fas fa-pen-fancy"
        title="Novo atendente"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import AttendantsList from '@/components/attendants/AttendantsList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertAttendant from '@/components/modals/ModalInsertAttendant'
export default {
  name: 'Home',
  components: {
    HeroBar,
    TitleBar,
    AttendantsList,
    FabButton,
    ModalInsertAttendant
  },
  data () {
    return {
      productToDelete: {},
      isComponentModalActive: false,
      attendantUpdating: null
    }
  },
  computed: {
    ...mapGetters(['allAttendants', 'fetchingAttendants', 'allDepartments', 'allPermissions']),
    titleStack () {
      return ['Cadastros', 'Atendentes']
    },
    usernames: {
      get () {
        return this.allAttendants.map(att => att.username)
      },
      set () {
        //
      }
    }
  },
  methods: {
    ...mapActions([
      'addAttendant',
      'updateAttendant',
      'fetchAttendants',
      'deleteAttendant',
      'fetchDepartments',
      'fetchPermissions'
    ]),
    handleUpdateAttendant (attendant) {
      this.updateAttendant(attendant)
        .then(() => this.closeModal())
        .catch(err => {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: err.response.data.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },
    handleAddAttendant (attendant) {
      this.addAttendant(attendant)
        .then(() => this.closeModal())
        .catch(err => {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: err.response.data.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },
    handleAddClick () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.attendantUpdating = null
      this.isComponentModalActive = false
    },
    removeAttendant (attendant) {
      this.deleteAttendant(attendant.id)
        .then(() => this.$buefy.toast.open('Atendente excluido com sucesso!'))
        .catch(() => {
          this.$buefy.toast.open(
            'Não é possível excluir este atendente, possivelmente ele possui relacionamentos com tickets!'
          )
        })
    },
    OpenUpdateAttendant (attendant) {
      this.attendantUpdating = attendant
      this.handleAddClick()
    }
  },
  created () {
    this.fetchAttendants()
    this.fetchDepartments()
    this.fetchPermissions()
  }
}
</script>
