<template>
  <div>
    <div v-if="allAttendants.length > 0">
      <b-table :data="allAttendants" :bordered="false" :striped="true" :narrowed="false" :hoverable="true"
        :loading="false" :focusable="false" :mobile-cards="true" scrollable>

        <!-- <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column> -->

        <b-table-column field="username" label="Username" v-slot="props">
          {{ props.row.username }}
        </b-table-column>

        <b-table-column field="name" label="Nome" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="departmentName" label="Departamentos" v-slot="props">
          <MultipleTags :v-if="props.row.departments" :tags="getAttendantDepartments(props.row.departments)" />
        </b-table-column>

        <b-table-column field="supervisor_all_departments" width="200" label="Supervisor todos os depart." v-slot="props">
          {{ props.row.supervisor_all_departments ? 'Sim' : 'Não' }}
        </b-table-column>

        <b-table-column field="custom_code" width="200" label="Código customizado" v-slot="props">
          {{ props.row.custom_code }}
        </b-table-column>

        <b-table-column field="createdAt" width="200" label="Criado em" v-slot="props">
          {{
            props.row.created_at
            ? formatDateTime(props.row.created_at)
            : ""
          }}
        </b-table-column>

        <b-table-column field="is_active" label="Ativo?" v-slot="props">
          <IsActiveTag :active="props.row.isActive" />
        </b-table-column>

        <b-table-column width="200" label="Atualizar" v-slot="props">
          <b-button type="is-warning is-light" outlined @click="updateAttendant(props.row)">Atualizar</b-button>
        </b-table-column>

      </b-table>
    </div>
  </div>
</template>

<script>

import mixin from '../../utils/mixins'
import IsActiveTag from '../commons/IsActiveTag.vue'
import MultipleTags from '../commons/MultipleTags.vue'

export default {
  name: 'AttendantsList',
  props: {
    attendants: {
      type: Array,
      required: true
    }
  },
  mixins: [mixin],
  components: {
    IsActiveTag,
    MultipleTags
  },
  computed: {
    allAttendants: {
      get () {
        return this.attendants
      }
    }
  },
  methods: {
    confirmAttendantDelete (attendant) {
      this.$buefy.dialog.confirm({
        title: 'Excluir contato',
        message: 'Deseja <b>excluir</b> este contato?',
        confirmText: 'Excluir contato',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeAttendant', attendant)
      })
    },
    updateAttendant (attendant) {
      this.$emit('updateAttendant', attendant)
    },
    getAttendantDepartments (dpts) {
      return dpts.map(el => ({ title: el.name, id: el.id, supervisor: el.supervisor }))
    }
  }
}
</script>

<style lang="scss" scoped></style>
